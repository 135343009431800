<template>
	<main class="page-container container">
		<div class="section__top mb-20">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					№{{ data.selectionNumber }}
				</AppText>
			</div>
		</div>

		<div class="section__body" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<product-details-table v-if="data && data.productList" :data="data.productList">
				<template #header>
					<th>№</th>
					<th style="max-width: 300px;">Наименование</th>
					<th style="max-width: 300px;">{{$t('amountShort')}}</th>
					<th style="max-width: 300px;">Ед.Изм.</th>
					<th style="max-width: 150px;">Стартовая цена за единицу</th>
					<th style="max-width: 300px;">Стартовая цена</th>
				</template>
				<template #body>
					<tr v-for="(item, idx) in data.productList" :key="idx">
						<td style="max-width: 300px; background: #F3F8FC">
							<AppText weight="600" size="14">{{ idx + 1 }}</AppText>
						</td>
						<td style="max-width: 300px; background: #F3F8FC">
							<AppText weight="600" size="14">{{ item.productName | translate }}</AppText>
						</td>
						<td style="max-width: 300px;">{{ item.quantity }}</td>
						<td style="max-width: 300px;">{{ item.name }}</td>
						<td style="max-width: 300px;"><b>{{ item.price | price }} UZS</b><br /></td>
						<td style="max-width: 300px;"><b>{{ (item.price * item.quantity) | price }} UZS</b></td>
					</tr>
				</template>
			</product-details-table>
			<div class="d-flex flex-column mb-20">
				<AppText class="mb-15" weight="500" size="13">
					Файлы для загрузки:
				</AppText>
				<div class="d-flex">
					<app-button-a
						v-for="(file, idx) in data.files"
						:key="idx"
						class="mr-15 box-shadow fw-500"
						width=""
						theme="white"
						@click="download(file.url)"
					>
						{{ file.name }}
						<img class="ml-15" width="12" height="15" src="@/assets/icons/download.svg" alt="" />
					</app-button-a>
				</div>
			</div>
			<!-- <div>
				<app-content-card class="custom-tab-component" padding-x="0" padding-y="0" :radius="[8, 8, 0, 0]">
					<app-tabs v-model="selectedTab" :list="tabItems" />
				</app-content-card>

				<customer-data-tab v-if="selectedTab === 'directContracts'" :data="[]"></customer-data-tab>
				<general-info-tab v-if="selectedTab === 'auction'" :data="[]"></general-info-tab>
				<criteria-tab v-if="selectedTab === 'criteria'" :data="[]"></criteria-tab>
				<contract-tab v-if="selectedTab === 'contract'" :data="[]"></contract-tab>
			</div> -->
		</div>
	</main>
</template>

<script>
import AppText from "@/components/shared-components/AppText";
import AppButtonA from "@/components/shared-components/AppButtonA";
import AppContentCard from "@/components/shared-components/AppContentCard";
import AppTabs from "@/components/shared-components/AppTabs";
import AppButton from "@/components/shared-components/AppButton";

import SelectionTimeCounter from "@/components/pages/selection/SelectionTimeCounter";
import ProductDetailsTable from "@/components/pages/selection/ProductDetailsTable";
import CustomerDataTab from "@/components/pages/selection/CustomerDataTab";
import GeneralInfoTab from "@/components/pages/selection/GeneralInfoTab";
import CriteriaTab from "@/components/pages/selection/CriteriaTab";
import ContractTab from "@/components/pages/selection/ContractTab";
import DownloadFiles from "@/components/pages/common/DownloadFiles";

import SelectionService from "@/services/api/selection-service";
import generateFileData from '@/helpers/generateFileData';

export default {
	name: "index",
	components: {
		AppText,
		AppButtonA,
		AppContentCard,
		AppTabs,
		AppButton,
		SelectionTimeCounter,
		ProductDetailsTable,
		CustomerDataTab,
		GeneralInfoTab,
		CriteriaTab,
		ContractTab,
		DownloadFiles
	},

	data() {
		return {
			tenderType: null,
			selectedTab: "directContracts",
			tabItems: [
				{
					name: this.$i18n.t("Информация о заказчике"),
					value: "directContracts"
				},
				{
					name: this.$i18n.t("Общие данные"),
					value: "auction"
				},
				{
					name: this.$i18n.t("Критерии"),
					value: "criteria"
				},
				{
					name: this.$i18n.t("Договор"),
					value: "contract"
				}
			],
			id: null,
			data: {},
			adminUrl: process.env.VUE_APP_ADMIN_URL
		};
	},
	mounted() {
		this.load();
	},
	methods: {
		load() {
			const id = this.$route.params?.id;

			if (!id) {
				this.$notify({ type: "error", text: this.$t('not_found') });
				return ;
			}

			SelectionService.getDirectPurchase(id)
			.then(response => {
				const res = response.data.result;
				this.data = res;
				this.data.files = generateFileData(res.attachContract);
			}, error => console.log(error));
			
		},
		download(fileUrl) {
			window.open(fileUrl, "_blank")
		}
	},
	beforeDestroy() {
		this.$store.commit('setContractData', null)
	}
};
</script>

<style lang="scss" scoped>
.secondary-table {
	text-align: center;
	background: #fafdff;

	th {
		background: var(--color-secondary);
		color: white;
		text-align: center;
		padding: 22px;
		border-right: 1px solid #684687;
	}
}

.secondary-table th {
	padding: 15px;
}
.go-to-btn {
	// padding: 30px 0;
	border-radius: 8px;
	font-size: 14px;
	height: 40px;
	line-height: 18px;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	font-weight: 600;
}
</style>
