const generateFileData = (file) => {console.log(file)
	let files = file.split(';');
	const resultFiles = [];

	for (let i = 0; i < files.length; i++) {
		let fileName = files[i].split('/');
		resultFiles.push({
			url: process.env.VUE_APP_BASE_URL_DOMAIN + files[i],
			name: fileName[files.length - 1].substr(0, 10)
		})
	}

	return resultFiles;
}

export default generateFileData;